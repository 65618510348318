import React from "react";
import "./Contact.css";

const Contact = ({ setActiveTab }) => {
    setActiveTab("Contact");

    return (
        <div className="Contact">
            <div className="contact_content">
                <div className="mail_contact">
                    <i className="fa fa-envelope-square" />
                    <a href="mailto:pro@hugo-vallee.fr">pro@hugo-vallee.fr</a>
                </div>
                <div className="linkedin_contact">
                    <i className="fa fa-linkedin-square" />
                    <a
                        href="https://www.linkedin.com/in/hugo-vall%C3%A9e-3b3b77193/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Hugo Vallée
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contact;

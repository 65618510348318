import React from "react";
import { useMediaQuery } from "react-responsive";
import { Card } from "semantic-ui-react";

import { projects } from "../../assets/other/data";
import ProjectCard from "../ProjectCard/ProjectCard";

import "./Projets.css";

const Projets = ({ setActiveTab }) => {
    setActiveTab("Projets");

    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

    return (
        <Card.Group
            centered
            itemsPerRow={!isPortrait ? 4 : 1}
            className="Projets"
            doubling
        >
            {projects.map((value, index) => (
                <ProjectCard {...value} key={index} />
            ))}
        </Card.Group>
    );
};

export default Projets;

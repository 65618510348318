import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Contact from "../Contact/Contact";
import Cv from "../Cv/Cv";
import Home from "../Home/Home";
import Parcours from "../Parcours/Parcours";
import Projets from "../Projets/Projets";

import "./Main.css";

const Main = ({ setActiveTab }) => {
    return (
        <div className="Main" style={{ width: "100%", height: "95%" }}>
            <Routes>
                <Route
                    path="/"
                    element={<Home setActiveTab={setActiveTab} />}
                />
                <Route
                    path="/parcours"
                    element={<Parcours setActiveTab={setActiveTab} />}
                />
                <Route
                    path="/projets"
                    element={<Projets setActiveTab={setActiveTab} />}
                />
                <Route
                    path="/cv"
                    element={<Cv setActiveTab={setActiveTab} />}
                />
                <Route
                    path="/contact"
                    element={<Contact setActiveTab={setActiveTab} />}
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
    );
};

export default Main;

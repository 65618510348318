// @ts-nocheck
import React, { useState } from "react";
import { Loader } from "semantic-ui-react";

import profil from "../../assets/img/photocv.jfif";
import "./Home.css";

import { homePageData as hpd } from "assets/other/data";

const Home = ({ setActiveTab }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    setActiveTab("Accueil");

    return (
        <div className="Home">
            <div className="home_box">
                <div className="profile_picture_contener">
                    <img
                        src={profil}
                        alt="LogoProfil"
                        className={
                            isImageLoaded ? "picture_profil" : "dont_display"
                        }
                        onLoad={() => setIsImageLoaded(true)}
                    />
                    {!isImageLoaded && (
                        <Loader
                            active
                            inline
                            size="massive"
                            className="picture_profil"
                            style={{ margin: "2rem" }}
                        >
                            Loading
                        </Loader>
                    )}
                </div>
                <div className="skill">
                    <h1 style={{ fontSize: "font-size: calc(10px + 2vmin)" }}>
                        {hpd && hpd.title}
                    </h1>
                    <h2 style={{ fontSize: "font-size: calc(10px + 2vmin)" }}>
                        {hpd && hpd.subTitle}
                    </h2>
                </div>
                <div className="skill_text">
                    <h3 style={{ fontSize: "font-size: calc(15px + 2vmin)" }}>
                        {hpd && hpd.desc}
                    </h3>
                </div>
                <div className="home_logo">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/hugo-vall%C3%A9e-3b3b77193/"
                    >
                        <i className="fa fa-linkedin-square" />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/HugoEyle"
                    >
                        <i className="fa fa-github-square" />
                    </a>
                    {/* <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/_Eyle_"
                    >
                        <i className="fa fa-square-x-twitter"></i>
                    </a> */}
                </div>
            </div>
        </div>
    );
};

export default Home;

import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import { Icon } from "semantic-ui-react";

import "./Parcours.css";

import { parcours as parcoursElements } from "assets/other/data";
import MyModal from "components/MyModal/MyModal";

const Parcours = ({ setActiveTab }) => {
    // const [open, setOpen] = React.useState(false);
    setActiveTab("Parcours");

    return (
        // @ts-ignore
        <VerticalTimeline animate>
            {parcoursElements &&
                parcoursElements.map((parcoursElement, index) => (
                    // @ts-ignore
                    <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element--work"
                        textClassName="parcours-text-container"
                        contentStyle={{
                            background: "black",
                            color: "#fff",
                        }}
                        contentArrowStyle={{
                            borderRight: "8px solid  rgb(255, 255, 255)",
                        }}
                        date={parcoursElement.date && parcoursElement.date}
                        dateClassName="timeline-date"
                        iconStyle={parcoursElement.iconStyle}
                        icon={
                            parcoursElement.icon && (
                                <Icon
                                    // @ts-ignore
                                    name={parcoursElement.icon}
                                    fitted
                                    size="big"
                                />
                            )
                        }
                        position={
                            parcoursElement.position && parcoursElement.position
                        }
                    >
                        <MyModal parcoursElement={parcoursElement} />
                    </VerticalTimelineElement>
                ))}
            {/* @ts-ignore */}
            <VerticalTimelineElement
                iconStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "rgb(16, 204, 82)",
                    color: "#fff",
                }}
                icon={<Icon name="home" fitted size="big" />}
            />
        </VerticalTimeline>
    );
};

export default Parcours;

import React, { useState } from "react";

import Main from "components/Main/Main";
import Navbar from "components/Navbar/Navbar";
import Waiting from "components/Waiting/Waiting";
import "semantic-ui-css/semantic.min.css";
import "./App.css";

const App = () => {
    const [activeTab, setActiveTab] = useState("Accueil");
    const loadingScreen = false;

    return (
        <>
            {!loadingScreen && <div className="background" />}
            <div className="App">
                {loadingScreen ? (
                    <Waiting />
                ) : (
                    <>
                        <Navbar
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        <Main setActiveTab={setActiveTab} />
                    </>
                )}
            </div>
        </>
    );
};

export default App;

import React, { useState } from "react";
import {
    Card,
    Container,
    Header,
    Image,
    Label,
    Modal,
} from "semantic-ui-react";

import "./ProjectCard.css";

const ProjectCard = ({ title, desc, img, link, label, images }) => {
    const [open, setOpen] = useState(false);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={link != null ? false : open}
            dimmer="blurring"
            closeIcon
            trigger={
                <Card
                    link={link != null}
                    href={link}
                    target="_blank"
                    className="project-card"
                >
                    <Image
                        src={img}
                        ui={false}
                        className="project-card-image"
                    />
                    <Card.Content>
                        <Card.Header className="project-card-text-color">
                            {title}
                        </Card.Header>
                        {/* <Card.Meta>
                    <span className="date">Joined in 2015</span>
                </Card.Meta> */}
                        <Card.Description className="project-card-text-color">
                            {desc}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra className="project-card-extra">
                        {label &&
                            label.map((value, index) => (
                                <Label
                                    key={index}
                                    color={value.color}
                                    className="project-card-label"
                                >
                                    {value.name}
                                </Label>
                            ))}
                    </Card.Content>
                </Card>
            }
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content image scrolling>
                <Image size="large" src={img} wrapped rounded />
                <Modal.Description className="project-card-modal-description">
                    <Header>Description</Header>
                    <p>{desc}</p>
                    <Header>Technologies</Header>
                    <Container>
                        {label &&
                            label.map((value, index) => (
                                <Label
                                    key={index}
                                    color={value.color}
                                    className="project-card-label"
                                >
                                    {value.name}
                                </Label>
                            ))}
                    </Container>
                    <Header>Illustrations</Header>
                    <Image.Group size="big">
                        <Image rounded src={img} />
                        {images &&
                            images.map((value, index) => (
                                <Image rounded src={value} key={index} />
                            ))}
                    </Image.Group>
                </Modal.Description>
            </Modal.Content>
            {/* <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Nope
                </Button>
                <Button
                    content="Yep, that's me"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => setOpen(false)}
                    positive
                />
            </Modal.Actions> */}
        </Modal>
    );
};

export default ProjectCard;

// @ts-nocheck
import React from "react";

// import cv from "../../assets/other/CV_PortFolio_Hugo_Vallée.pdf";
import "./Cv.css";

const Cv = ({ setActiveTab }) => {
    setActiveTab("CV");

    return (
        <div className="cv">
            {/* <embed src={cv} type="application/pdf" className="cvpdf" /> */}
        </div>
    );
};

export default Cv;

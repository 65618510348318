import { useMediaQuery } from "react-responsive";
import React, { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";

import "./Navbar.css";

const Navbar = ({ activeTab, setActiveTab }) => {
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

    return (
        <div style={{ position: "relative", height: "5%" }}>
            {!isPortrait ? (
                <Menu borderless secondary color="red" size="huge">
                    <Menu.Item
                        name="Accueil"
                        active={activeTab === "Accueil"}
                        onClick={() => setActiveTab("Accueil")}
                        as={Link}
                        to="/"
                    />

                    <Menu.Item
                        name="Parcours"
                        active={activeTab === "Parcours"}
                        onClick={() => setActiveTab("Parcours")}
                        as={Link}
                        to="/parcours"
                    />

                    <Menu.Item
                        name="Projets"
                        active={activeTab === "Projets"}
                        onClick={() => setActiveTab("Projets")}
                        as={Link}
                        to="/projets"
                    />

                    {/* <Menu.Item
                        name="CV"
                        active={activeTab === "CV"}
                        onClick={() => setActiveTab("CV")}
                        as={Link}
                        to="/CV"
                    /> */}

                    <Menu.Item
                        name="Contact"
                        active={activeTab === "Contact"}
                        onClick={() => setActiveTab("Contact")}
                        as={Link}
                        to="/contact"
                    />
                </Menu>
            ) : (
                <Menu borderless secondary fluid>
                    <Dropdown as={Icon} item icon="bars" size="large">
                        <Dropdown.Menu>
                            <Dropdown.Item text="Accueil" as={Link} to="/" />
                            <Dropdown.Item
                                text="Parcours"
                                as={Link}
                                to="/parcours"
                            />
                            <Dropdown.Item
                                text="Projets"
                                as={Link}
                                to="/projets"
                            />
                            <Dropdown.Item text="CV" as={Link} to="/cv" />
                            <Dropdown.Item
                                text="Contact"
                                as={Link}
                                to="/contact"
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
            )}
        </div>
    );
};

export default Navbar;
